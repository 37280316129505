<template>
  <div>
    <h2 class="header-title">经销商渠道管理</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of entryList"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item">
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
    <h2 class="header-title">直营门店管理</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of directList"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item">
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
    <h2 class="header-title">经典经销商渠道管理</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of classicalDistributorChannelManagement"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item">
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
    <h2 class="header-title">海外经销商渠道管理</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of overseasDistributorChannelManagement"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item">
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
    <h2 class="header-title">BPM</h2>
    <ul class="entry-list">
      <li
        v-for="entryItem of bpmChannelManagement"
        :key="entryItem.type"
        v-permission="[entryItem.perm]"
        class="entry-item">
        <el-button class="btn" type="primary" @click="goToFlow(entryItem.type)">
          {{ entryItem.title }}
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      entryList: [
        {
          title: '区域加盟申请表',
          type: 'areaFlow',
          perm: 'process_apply_001'
        },
        {
          title: '门店系列管理申请表',
          type: 'shopSeriesManagement',
          perm: 'process_apply_002'
        },
        {
          title: '经销商店面重装申请表',
          type: 'dealerStoreRenovation',
          perm: 'process_apply_003'
        },
        {
          title: '老经销商增加新店申请表',
          type: 'newShopFlow',
          perm: 'process_apply_004'
        },
        {
          title: '老经销商增加新系列申请表',
          type: 'newSeriesFlow',
          perm: 'process_apply_005'
        },
        {
          title: '客户主数据修改申请表',
          type: 'customerMainChange',
          perm: 'process_apply_006'
        },
        {
          title: '渠道合作申请表',
          type: 'channelFlow',
          perm: 'process_apply_007'
        },
        {
          title: '经销权转让申请表',
          type: 'dealershipTransfer',
          perm: 'process_apply_008'
        },
        {
          title: '经销商撤店申请流程',
          type: 'dealerRemoveStore',
          perm: 'process_apply_009'
        },
        {
          title: '经销权取消申请流程',
          type: 'dealershipCancel',
          perm: 'process_apply_013'
        },
        {
          title: '经销商考核补贴流程',
          type: 'subsidyVerification',
          perm: 'process_apply_017'
        },
        {
          title: '经销商意向加盟申请表',
          type: 'dealerInterview',
          perm: 'process_apply_019'
        },
        {
          title: '新渠道经销商合作方信息报备流程',
          type: 'newChannelDealerPartnerReport',
          perm: 'process_apply_020'
        }
      ],
      directList: [
        {
          title: '直营新店流程',
          type: 'newShopFlow?type=direct',
          perm: 'process_apply_014'
        },
        {
          title: '直营撤店流程',
          type: 'dealerRemoveStore?type=direct',
          perm: 'process_apply_015'
        },
        {
          title: '直营转让流程',
          type: 'dealershipTransfer?type=direct',
          perm: 'process_apply_016'
        }
      ],
      // 经典经销商渠道管理
      classicalDistributorChannelManagement: [
        {
          title: '慕思经典经销商加盟流程申请表',
          type: 'classicalAreaFlow',
          perm: 'process_apply_022'
        },
        {
          title: '慕思经典经销商门店管理申请表',
          type: 'storeManagementFlow',
          perm: 'process_apply_021'
        },
        {
          title: '慕思经典经销权转让申请表',
          type: 'classicDealershipTransfer',
          perm: 'process_apply_024'
        }
      ],
      // 海外经销商渠道管理
      overseasDistributorChannelManagement: [
        {
          title: '海外渠道合作申请表',
          type: 'overseasChannelFlow',
          perm: 'process_apply_023'
        },
        {
          title: '希诺米海外渠道合作申请流程',
          type: 'overseasSinomiChannelFlow',
          perm: 'process_apply_025'
        }
      ],
      bpmChannelManagement: [
        {
          title: '慕思经典经销商加盟流程申请表',
          type: 'bpmFlowClassicalAreaFlow',
          perm: 'process_apply_bpm_01'
        },
        {
          title: '慕思经典经销商门店管理申请表',
          type: 'bpmFlowStoreManagementFlow',
          perm: 'process_apply_bpm_02'
        },
        {
          title: '经销商4S星级认证流程',
          type: 'bpmFlowDealer4SCertification',
          perm: 'process_apply_026'
        },
        {
          title: '新渠道经销商合作网点报备流程',
          type: 'bpmFlowNewChannelDealerNetworkReport',
          perm: 'process_apply_bpm_03'
        },
        {
          title: '慕思经典-经销商主动放弃经销权申请流程',
          type: 'bpmFlowDealerGiveUpDealership',
          perm: 'process_apply_bpm_04'
        },
        {
          title: '终端门店整改申诉流程',
          type: 'bpmFlowStoreRectificationAppeal',
          perm: 'process_apply_bpm_05'
        },
        {
          title: '慕思经典-门店设计流程',
          type: 'bpmFlowClassicalStoreDesignFlow',
          perm: 'process_apply_bpm_06'
        },
        {
          title: '慕思经典-门店装修流程',
          type: 'bpmFlowClassicalStoreDecorationFlow',
          perm: 'process_apply_bpm_07'
        },
        {
          title: '慕思经典-门店状态调整流程',
          type: 'bpmFlowClassicalStoreStatusChangeFlow',
          perm: 'process_apply_bpm_08'
        },
        {
          title: '海外经销商加盟流程',
          type: 'bpmFlowOverseasDealersJoin',
          perm: 'process_apply_bpm_09'
        },
        {
          title: '经典VMD标准外申请流程',
          type: 'bpmFlowClassicalVMDFlow',
          perm: 'process_apply_bpm_10'
        }
      ]
    }
  },
  methods: {
    goToFlow(type) {
      if (!type) return
      this.$router.push({ path: `/flow/${type}` })
    }
  }
}
</script>

<style scoped lang="scss">
.entry-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  & > li {
    margin: 10px;
  }
}
.header-title {
  margin-top: 20px;
  padding-left: 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 760px) {
  .entry-list li {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}
</style>
