<template>
  <FlowForm
    title="经销权取消申请流程"
    :tips="[
      '1、经销权取消申请流程：针对经销商自动放弃已代理系列',
      '2、若取消的系列仍存在正常营业的门店，不允许走该流程，门店若为综合店，则需先走门店系列调整流程，失效掉门店的该系列；门店若为品牌店，则需要走撤店流程则可直接申请该流程，流程归档后，失效掉该门店'
    ]"
    :model="form"
    ref="form"
    :getDetail="initFlowForm"
    :onSubmit="onSubmit">
    <RequiredFormItemInput
      class="formItem"
      label="标题"
      :value="form.title"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemInput
      class="formItem"
      label="单号"
      :value="form.number"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemInput
      class="formItem"
      label="招商单号"
      :value="form.zsOrderNumber"
      disabled
      :required="false"
      placeholder="自动生成" />
    <HeaderTitle title="经销商个人基础信息" />
    <RequiredFormItemInput
      class="formItem"
      label="提单人工号"
      :value="form.applicant.employeeNumber"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemInput
      class="formItem"
      label="提单人"
      :value="form.applicant.name"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemInput
      class="formItem"
      label="提单部门"
      :value="form.applicant.departmentName"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemInput
      class="formItem"
      label="提单日期"
      :value="form.applicant.submittedTime"
      disabled
      :required="false"
      placeholder="自动生成" />
    <RequiredFormItemSelect
      class="formItem"
      label="售达方"
      labelKey="licenseNumber"
      valueKey="id"
      v-model="form.selectedBusinessLicense.id"
      :disabled="isOA"
      prop="selectedBusinessLicense.id"
      :rules="[{ required: true, trigger: 'blur' }]"
      :selectProps="{
        filterable: true,
        remote: true,
        'popper-class': 'custom-business-select'
      }"
      :totalCount="form.businessLicensesCount"
      :getDataList="getBusinessLicensesPaginated"
      :getNextList="getBusinessLicensesPaginated"
      @onOptionsListNotFound="onBusinessLicensesNotFound"
      @changeSelectedItem="onBusinessLicenseChange"
      @change="
        () => {
          form.authorizedCities = []
          form.agentSeriesList = []
          form.selectedAuthorizedCity = {
            id: '',
            name: '',
            provinceCode: '',
            provinceName: '',
            cityCode: '',
            cityName: '',
            cityLevel: '',
            districtCode: '',
            districtName: ''
          }
          form.marketCenter.name = ''
          form.marketCenter.code = ''
          form.marketCenter.brandRegionCode = null
        }
      ">
      <template #default="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemInput
      class="formItem"
      label="经销商（实际控股人）"
      :value="form.dealer.name"
      disabled
      placeholder="请先选择售达方" />
    <RequiredFormItemInput
      class="formItem"
      label="经销商（实际控股人）联系方式"
      :value="form.dealer.contact"
      disabled
      placeholder="请先选择售达方" />
    <RequiredFormItemSelect
      class="formItem"
      :disabled="isOA"
      labelKey="authorizedCityName"
      label="授权城市"
      valueKey="authorizedCityCode"
      prop="selectedAuthorizedCity.id"
      v-model="form.selectedAuthorizedCity.id"
      :rules="[{ trigger: 'blur' }]"
      :dataList="form.authorizedCities"
      :selectProps="{
        disabled: !form.selectedBusinessLicense.id
      }"
      @change="onAuthorizedCityChange" />
    <RequiredFormItemInput
      class="formItem"
      v-for="(value, key) in regions"
      :key="key"
      :label="value"
      :value="form.selectedAuthorizedCity[key]"
      disabled
      placeholder="请先选择授权城市" />
    <RequiredFormItemSelect
      class="formItem"
      label="品牌类型"
      labelKey="name"
      v-model="form.selectedBrandType.id"
      valueKey="id"
      :disabled="isOA"
      prop="selectedBrandType.id"
      :rules="[{ trigger: 'blur' }]"
      :dataList="form.brandTypes"
      @change="onBrandTypeChange" />
    <RequiredFormItemInput
      class="formItem"
      label="营销中心"
      placeholder="自动带出"
      :value="form.marketCenter.name"
      disabled
      prop="marketCenter.code" />
    <RequiredFormItemInput
      class="formItem"
      label="品牌区域编码"
      placeholder="自动带出"
      :value="form.marketCenter.brandRegionCode"
      disabled
      prop="marketCenter.brandRegionCode" />
    <RequiredFormItemSelect
      v-if="form.selectedBrandType.id === '1390119720541401089'"
      class="formItem"
      label="是否新商"
      labelKey="label"
      v-model="form.isNewBusiness"
      valueKey="value"
      :disabled="isOA"
      prop="isNewBusiness"
      :dataList="[
        {
          label: '是',
          value: 1
        },
        {
          label: '否',
          value: 0
        }
      ]" />
    <RequiredFormItemInput
      v-if="isOA && form.lastYearRevenue !== ''"
      class="formItem"
      delay-update
      v-model="form.lastYearRevenue"
      label="上一年提货业绩（万）"
      prop="lastYearRevenue"
      :inputProps="{ type: 'number' }"
      disabled />
    <RequiredFormItemInput
      class="formItem"
      label="已代理系列"
      :value="form.agentSeriesList.map(item => item.name).join(',')"
      placeholder="请先选择授权城市"
      disabled />
    <RequiredFormItemInput
      class="formItem"
      v-if="isOA && form.selectedCancelSeriesList.length > 0"
      label="取消授权系列"
      :value="form.selectedCancelSeriesList.join(',')"
      disabled />
    <RequiredFormItemInput
      v-if="isOA && form.canceledSeriesReason !== ''"
      style="width: 100%"
      class="formItem"
      label="取消授权系列原因"
      v-model="form.canceledSeriesReason"
      prop="canceledSeriesReason"
      disabled />
    <RemarkItem
      v-if="isOA && (form.uploadFiles.length > 0 || form.remark !== '')"
      v-model="form.remark"
      disabled
      :fileList="form.uploadFiles"
      @onUpload="
        file => {
          form.uploadFiles = file.map(item => item.response)
        }
      " />
  </FlowForm>
</template>

<script>
// @ts-nocheck
import { mapState, mapGetters } from 'vuex'
import dateFormat from 'dateformat'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import FlowForm from './components/flowForm.vue'
import HeaderTitle from './components/headerTitle.vue'
import RemarkItem from './components/remarkItem.vue'
import * as BusinessApi from '@/api/attractPlatform/business'
import * as commonApi from '@/api/common'
import {
  DealershipCancelApplication,
  addDealershipCancelApplication,
  saveDealershipCancelApplicationDraft,
  getDealershipCancelApplication,
  getCityLevel
} from '@/api/flow/dealershipCancel'

export default {
  components: {
    FlowForm,
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RemarkItem
  },
  computed: {
    ...mapState({
      business: state => state.user.business,
      isOA: state => state.dealershipTransfer.isOA
    }),
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap'])
  },
  watch: {
    userInfo: {
      handler(userInfo) {
        if (userInfo && !this.$route.query.id) {
          this.form.applicant.id = userInfo.accountId
          this.form.applicant.name = userInfo.realName
          this.form.applicant.employeeNumber = userInfo.code
          this.form.applicant.departmentId = userInfo.deptId
          this.form.applicant.departmentName = userInfo.deptName
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      regions: {
        provinceName: '省份',
        cityName: '城市',
        districtName: '区/县'
      },
      /**表单 */
      form: {
        /**表单 id */
        id: '',
        zsOrderNumber: '',
        /**表单标题 */
        title: '经销权取消申请流程',
        /**表单号 */
        number: '',
        /**
         * 表单状态（0：草稿，10：提交发布）
         * @type {0|10}
         */
        status: 0,
        /**
         * 表单级别（0：正常，1：紧急）
         * @type {0|1}
         */
        urgentLevel: 0,
        /**紧急说明 */
        urgentExplain: '',

        /**提单人 */
        applicant: {
          /**提单人 id */
          id: '',
          /**提单人名称 */
          name: '',
          /** 提单人工号 */
          employeeNumber: '',
          /**提单部门 id */
          departmentId: '',
          /**提单部门*/
          departmentName: '',
          /**提单日期 */
          submittedTime: ''
        },

        /**售达方列表 */
        businessLicenses: [],
        businessLicensesCount: 0,
        selectedBusinessLicense: {
          id: '',
          name: '',
          number: ''
        },

        /**经销商 */
        dealer: {
          id: '',
          name: '',
          contact: ''
        },

        /**授权城市列表 */
        authorizedCities: [],
        selectedAuthorizedCity: {
          /** 授权城市code! */
          id: '',
          name: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: '',
          cityLevel: '',
          districtCode: '',
          districtName: ''
        },

        /**品牌类型列表 */
        brandTypes: [],
        /**选中的品牌类型 */
        selectedBrandType: {
          id: '',
          name: '',
          code: ''
        },
        marketCenters: [],
        /**选中的营销中心 */
        marketCenter: {
          name: '',
          code: '',
          /**品牌区域编码 */
          brandRegionCode: ''
        },

        /**上一年提货业绩 */
        lastYearRevenue: '',

        /** 已代理系列列表 */
        agentSeriesList: [],

        /**取消授权系列列表 */
        canceledSeriesList: [],
        selectedCancelSeriesList: [],
        /**取消授权系列原因 */
        canceledSeriesReason: '',
        /**备注 */
        remark: '',
        /**上传附件列表 */
        uploadFiles: [],
        isNewBusiness: null
      }
    }
  },
  methods: {
    /**
     * @description 提交事件
     * @param {'save'|'submit'} type
     */
    async onSubmit(type) {
      const { form } = this

      const {
        applicant,
        selectedBusinessLicense,
        dealer,
        selectedAuthorizedCity,
        selectedBrandType
      } = form
      /**@type {DealershipCancelApplication} */
      const payload = {
        brandRegionCode: '',
        dealerDeclarationForm: {
          id: form.id || undefined,
          title: this.getFlowTitle(selectedAuthorizedCity.name, dealer.name),
          optUserId: applicant.id,
          optUserName: applicant.name,
          optJobNumber: applicant.employeeNumber,
          optDeptId: applicant.departmentId,
          optDeptName: applicant.departmentName,
          optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
        },
        dealerBoss: {
          id: dealer.id || undefined,
          realName: dealer.name || undefined,
          contactInfo: dealer.contact || undefined
        },
        dealerBusinessLicense: {
          id: selectedBusinessLicense.id,
          bossId: dealer.id,
          declarationFormId: form.id || undefined
        },
        dealerClearBrandInfo: {
          provinceCode: selectedAuthorizedCity.provinceCode,
          provinceName: selectedAuthorizedCity.provinceName,
          authorizedCityCode: selectedAuthorizedCity.id,
          authorizedCityName: selectedAuthorizedCity.name,
          cityCode: selectedAuthorizedCity.cityCode,
          cityName: selectedAuthorizedCity.cityName,
          cityLevel: selectedAuthorizedCity.cityLevel,
          areaCode: selectedAuthorizedCity.districtCode,
          areaName: selectedAuthorizedCity.districtName,
          brandId: selectedBrandType.id,
          brandType: selectedBrandType.name,
          bigBrandCode: selectedBrandType.code,
          performance: form.lastYearRevenue,
          agentBrand: form.agentSeriesList.map(item => item.name).join(','),
          agentBrandId: form.agentSeriesList.map(item => item.id).join(','),
          clearBrandSeries: [],
          clearBrandReason: form.canceledSeriesReason,
          remark: form.remark,
          bankFiles: form.uploadFiles,
          marketCenter: form.marketCenter.name,
          marketCenterCode: form.marketCenter.code,
          isNewBusiness: form.isNewBusiness
        }
      }
      if (this.userInfo && !this.isOA) {
        // 未到 OA 流程
        payload.dealerDeclarationForm.optUserId = this.userInfo.accountId
        payload.dealerDeclarationForm.optUserName = this.userInfo.realName
        payload.dealerDeclarationForm.optJobNumber = this.userInfo.code
        payload.dealerDeclarationForm.optDeptId = this.userInfo.deptId
        payload.dealerDeclarationForm.optDeptName = this.userInfo.deptName
      }
      if (type === 'submit') {
        // 提交

        // 增加 bigBrandCode 传参，兼容旧数据
        if (!payload.dealerClearBrandInfo.bigBrandCode) {
          const current = form.brandTypes.find(
            ({ id }) => id === payload.dealerClearBrandInfo.brandId
          )
          if (current) {
            payload.dealerClearBrandInfo.bigBrandCode = current.code
          }
        }

        // 增加品牌区域编码传参
        await this.getMarketCenter()
        payload.brandRegionCode = form.marketCenter.brandRegionCode || null
        await addDealershipCancelApplication(payload)
      }
      if (type === 'save') {
        // 保存
        await saveDealershipCancelApplicationDraft(payload)
      }
    },
    /**
     * @description 初始化表单
     * @param {'detail'} type
     */
    async initFlowForm(type) {
      this.getBrandTypes()
      if (type !== 'detail') return
      // 编辑状态
      const res = await getDealershipCancelApplication(this.$route.query.id)

      this.form.id = res.dealerDeclarationForm.id
      this.form.number = res.dealerDeclarationForm.orderNumber
      this.form.zsOrderNumber = res.dealerDeclarationForm.zsOrderNumber
      this.form.title = res.dealerDeclarationForm.title
      this.form.status = res.dealerDeclarationForm.status

      this.form.applicant.id = res.dealerDeclarationForm.optUserId
      this.form.applicant.name = res.dealerDeclarationForm.optUserName
      this.form.applicant.employeeNumber =
        res.dealerDeclarationForm.optJobNumber
      this.form.applicant.departmentId = res.dealerDeclarationForm.optDeptId
      this.form.applicant.departmentName = res.dealerDeclarationForm.optDeptName
      this.form.applicant.submittedTime = res.dealerDeclarationForm.optTime

      this.form.selectedBusinessLicense.id = res.dealerBusinessLicense.id
      this.form.selectedBusinessLicense.name = res.dealerBusinessLicense.id

      this.form.dealer.id = res.dealerBoss.id
      this.form.dealer.name = res.dealerBoss.realName
      this.form.dealer.contact = res.dealerBoss.contactInfo

      this.form.selectedAuthorizedCity.id =
        res.dealerClearBrandInfo.authorizedCityCode
      this.form.selectedAuthorizedCity.name =
        res.dealerClearBrandInfo.authorizedCityName
      this.form.selectedAuthorizedCity.provinceCode =
        res.dealerClearBrandInfo.provinceCode
      this.form.selectedAuthorizedCity.provinceName =
        res.dealerClearBrandInfo.provinceName
      this.form.selectedAuthorizedCity.cityCode =
        res.dealerClearBrandInfo.authorizedCityCode
      this.form.selectedAuthorizedCity.cityName =
        res.dealerClearBrandInfo.authorizedCityName
      this.form.selectedAuthorizedCity.cityLevel =
        res.dealerClearBrandInfo.cityLevel
      this.form.selectedAuthorizedCity.districtCode =
        res.dealerClearBrandInfo.areaCode
      this.form.selectedAuthorizedCity.districtName =
        res.dealerClearBrandInfo.areaName

      this.form.selectedBrandType.id = res.dealerClearBrandInfo.brandId
      this.form.selectedBrandType.name = res.dealerClearBrandInfo.brandType

      this.form.marketCenter.name = res.dealerClearBrandInfo.marketCenter
      this.form.marketCenter.code = res.dealerClearBrandInfo.marketCenterCode
      this.form.marketCenter.brandRegionCode = res.brandRegionCode

      this.form.lastYearRevenue = res.dealerClearBrandInfo.performance

      this.form.canceledSeriesReason = res.dealerClearBrandInfo.clearBrandReason

      this.form.remark = res.dealerClearBrandInfo.remark
      this.form.uploadFiles = res.dealerClearBrandInfo.bankFiles
      this.form.isNewBusiness = res.dealerClearBrandInfo.isNewBusiness
      if (this.isOA) {
        this.form.selectedAuthorizedCity.id =
          res.dealerClearBrandInfo.authorizedCityCode
        this.form.agentSeriesList = [
          { name: res.dealerClearBrandInfo.agentBrand }
        ]
        this.form.selectedCancelSeriesList =
          res.dealerClearBrandInfo.clearBrandSeries.map(item => item.name)
      } else {
        this.fetchAgentSeriesList(
          this.form.selectedBusinessLicense.id,
          this.form.selectedAuthorizedCity.id
        )
        await this.getAuthorizedCities(
          this.form.selectedBusinessLicense.id,
          this.form.dealer.id
        )
        if (this.form.selectedAuthorizedCity.id) {
          console.log(this.form.authorizedCities)
          const item = this.form.authorizedCities.find(
            v => v.authorizedCityCode === this.form.selectedAuthorizedCity.id
          )
          if (item) {
            this.form.selectedAuthorizedCity.provinceCode = item.provinceCode
            this.form.selectedAuthorizedCity.provinceName = item.province
            this.form.selectedAuthorizedCity.cityCode = item.cityCode
            this.form.selectedAuthorizedCity.cityName = item.city
            this.form.selectedAuthorizedCity.districtCode = item.areaCode
            this.form.selectedAuthorizedCity.districtName = item.area
          }
        }
      }
    },
    /**获取售达方列表分页 */
    async getBusinessLicensesPaginated({
      page,
      searchText,
      businessLicenseId
    }) {
      const res = await BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      })
      // @ts-ignore
      this.form.businessLicensesCount = res.totalCount
      this.form.businessLicenses = res.data
      return res.data
    },
    /**售达方改变事件 */
    onBusinessLicenseChange(item) {
      if (!item) return
      this.form.selectedBusinessLicense.id = item.id
      this.form.selectedBusinessLicense.name = item.businessLicenseName
      this.form.selectedBusinessLicense.number = item.licenseNumber
      this.form.dealer.id = item.bossId
      this.form.dealer.name = item.realName
      this.form.dealer.contact = item.contactInfo
      this.getAuthorizedCities(
        this.form.selectedBusinessLicense.id,
        this.form.dealer.id
      )
    },
    /**售达方列表无数据事件 */
    async onBusinessLicensesNotFound({ value: businessLicenseId }, callback) {
      const res = await BusinessApi.license({
        businessLicenseId
      })
      callback(res.data)
    },
    /**
     * 获取授权城市列表
     * @param {string} businessLicenseId 售达方 id
     * @param {string} dealerId 经销商 id
     */
    async getAuthorizedCities(businessLicenseId, dealerId) {
      const res = await commonApi.getAuthorizedCityData({
        currPage: 1,
        pageSize: 100,
        businessLicenseId: businessLicenseId,
        bossId: dealerId,
        ifEnabled: 0
      })
      this.form.authorizedCities = res.data
    },
    /**授权城市改变事件 */
    async onAuthorizedCityChange(item) {
      const current = this.form.authorizedCities.find(
        ({ authorizedCityCode }) => authorizedCityCode === item
      )
      if (!current) return
      this.form.selectedAuthorizedCity = {
        id: current.authorizedCityCode,
        name: current.authorizedCityName,
        provinceCode: current.provinceCode,
        provinceName: current.province,
        cityCode: current.cityCode,
        cityName: current.city,
        cityLevel: '',
        districtCode: current.areaCode,
        districtName: current.area
      }
      this.form.marketCenter.name = ''
      this.form.marketCenter.code = ''
      this.form.marketCenter.brandRegionCode = null
      const { businessLicenseId, authorizedCityCode } = current
      if (!businessLicenseId || !authorizedCityCode) return
      await this.fetchAgentSeriesList(businessLicenseId, authorizedCityCode)
      this.form.selectedBrandType.id = ''
      this.form.selectedBrandType.name = ''
    },
    async getBrandTypes() {
      const res = await commonApi.brandseriesList()
      const resFiltered = res.data.filter(item => item.brandLevel === 0)
      this.form.brandTypes = resFiltered
    },
    /**品牌类型改变事件 */
    async onBrandTypeChange(item) {
      this.form.isNewBusiness = null
      const current = this.form.brandTypes.find(({ id }) => id === item)
      this.form.marketCenter.name = ''
      this.form.marketCenter.code = ''
      this.form.selectedAuthorizedCity.cityLevel = ''
      if (!current) return
      this.form.selectedBrandType.id = current.id
      this.form.selectedBrandType.name = current.name
      this.form.selectedBrandType.code = current.code
      if (this.form.selectedAuthorizedCity.id) {
        this.getMarketCenter()
        const cityLevel = await getCityLevel(
          this.form.selectedAuthorizedCity.id,
          current.id
        )
        if (cityLevel) {
          this.form.selectedAuthorizedCity.cityLevel = cityLevel.marketLevelName
        }
      }
    },
    /**获取已代理系列列表 */
    async fetchAgentSeriesList(businessLicenseId, authorizedCityCode) {
      const res = await commonApi.getShopBrandData({
        businessLicenseId,
        authorizedCityCode,
        pageSize: 100
      })
      this.form.agentSeriesList = res.data.map(item => ({
        id: item.brandId,
        name: item.name,
        code: item.code,
        parentId: item.bigBrandId
      }))
    },
    /**获取营销中心 */
    async getMarketCenter() {
      const { data } = await commonApi.requestGetSizeBarListByDeptMerge({
        deptId: null,
        brandIds: this.form.selectedBrandType.id,
        provinceCode: this.form.selectedAuthorizedCity.provinceCode,
        cityCode: this.form.selectedAuthorizedCity.cityCode,
        areaCode: this.form.selectedAuthorizedCity.districtCode
      })
      this.form.marketCenter.name = data?.smallName ?? ''
      this.form.marketCenter.code = data?.smallCode ?? ''
      this.form.marketCenter.brandRegionCode = data?.brandRegionCode ?? null
    },
    getFlowTitle(authorizedCity = '', dealerName = '', series = []) {
      const parts = [
        authorizedCity,
        dealerName,
        series.length > 0 ? `取消授权系列：${series.join(',')}` : ``
      ].filter(Boolean)
      return `经销权取消流程（${parts.join('，')}）`
    }
  }
}
</script>
