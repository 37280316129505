/*
 * @Author: wangmq
 * @Date: 2023-09-01 09:45:56
 * @LastEditors: wangmq
 * @LastEditTime: 2023-09-05 17:23:06
 */
// import BIhttp from '@/utils/BI-request'

// // 获取近三年提货情况
// export const requestGetPickUp = data => {
//   return BIhttp.post('/api/get_achievement_by_dealer', data, {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }

import http from '@/utils/request'
/**
 * @description: 获取近三年提货情况
 * @param {import('vue').PropType<{
 *  dealer_code:string,
 * year_desc: string
 * }>} data
 * @param {string} data.dealer_code 经销商code
 * @param {string} data.year_desc 年份
 * @see http://10.12.0.64/docs/operate/operate-1f0flm3euu9of
 */
export const requestGetPickUp = data => {
  return http({
    url: '/dealermanage/biService/postJson',
    method: 'get',
    params: {
      url: '/api/get_achievement_by_dealer',
      ...data
    }
  })
}
