var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FlowForm',{ref:"form",attrs:{"title":"经销权取消申请流程","tips":[
    '1、经销权取消申请流程：针对经销商自动放弃已代理系列',
    '2、若取消的系列仍存在正常营业的门店，不允许走该流程，门店若为综合店，则需先走门店系列调整流程，失效掉门店的该系列；门店若为品牌店，则需要走撤店流程则可直接申请该流程，流程归档后，失效掉该门店'
  ],"model":_vm.form,"getDetail":_vm.initFlowForm,"onSubmit":_vm.onSubmit}},[_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"标题","value":_vm.form.title,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"单号","value":_vm.form.number,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"招商单号","value":_vm.form.zsOrderNumber,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('HeaderTitle',{attrs:{"title":"经销商个人基础信息"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"提单人工号","value":_vm.form.applicant.employeeNumber,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"提单人","value":_vm.form.applicant.name,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"提单部门","value":_vm.form.applicant.departmentName,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"提单日期","value":_vm.form.applicant.submittedTime,"disabled":"","required":false,"placeholder":"自动生成"}}),_c('RequiredFormItemSelect',{staticClass:"formItem",attrs:{"label":"售达方","labelKey":"licenseNumber","valueKey":"id","disabled":_vm.isOA,"prop":"selectedBusinessLicense.id","rules":[{ required: true, trigger: 'blur' }],"selectProps":{
      filterable: true,
      remote: true,
      'popper-class': 'custom-business-select'
    },"totalCount":_vm.form.businessLicensesCount,"getDataList":_vm.getBusinessLicensesPaginated,"getNextList":_vm.getBusinessLicensesPaginated},on:{"onOptionsListNotFound":_vm.onBusinessLicensesNotFound,"changeSelectedItem":_vm.onBusinessLicenseChange,"change":() => {
        _vm.form.authorizedCities = []
        _vm.form.agentSeriesList = []
        _vm.form.selectedAuthorizedCity = {
          id: '',
          name: '',
          provinceCode: '',
          provinceName: '',
          cityCode: '',
          cityName: '',
          cityLevel: '',
          districtCode: '',
          districtName: ''
        }
        _vm.form.marketCenter.name = ''
        _vm.form.marketCenter.code = ''
        _vm.form.marketCenter.brandRegionCode = null
      }},scopedSlots:_vm._u([{key:"default",fn:function({ scope }){return [_c('span',[_vm._v(_vm._s(scope.row.licenseNumber))]),_c('small',[_vm._v(_vm._s(scope.row.businessLicenseName))])]}}]),model:{value:(_vm.form.selectedBusinessLicense.id),callback:function ($$v) {_vm.$set(_vm.form.selectedBusinessLicense, "id", $$v)},expression:"form.selectedBusinessLicense.id"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"经销商（实际控股人）","value":_vm.form.dealer.name,"disabled":"","placeholder":"请先选择售达方"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"经销商（实际控股人）联系方式","value":_vm.form.dealer.contact,"disabled":"","placeholder":"请先选择售达方"}}),_c('RequiredFormItemSelect',{staticClass:"formItem",attrs:{"disabled":_vm.isOA,"labelKey":"authorizedCityName","label":"授权城市","valueKey":"authorizedCityCode","prop":"selectedAuthorizedCity.id","rules":[{ trigger: 'blur' }],"dataList":_vm.form.authorizedCities,"selectProps":{
      disabled: !_vm.form.selectedBusinessLicense.id
    }},on:{"change":_vm.onAuthorizedCityChange},model:{value:(_vm.form.selectedAuthorizedCity.id),callback:function ($$v) {_vm.$set(_vm.form.selectedAuthorizedCity, "id", $$v)},expression:"form.selectedAuthorizedCity.id"}}),_vm._l((_vm.regions),function(value,key){return _c('RequiredFormItemInput',{key:key,staticClass:"formItem",attrs:{"label":value,"value":_vm.form.selectedAuthorizedCity[key],"disabled":"","placeholder":"请先选择授权城市"}})}),_c('RequiredFormItemSelect',{staticClass:"formItem",attrs:{"label":"品牌类型","labelKey":"name","valueKey":"id","disabled":_vm.isOA,"prop":"selectedBrandType.id","rules":[{ trigger: 'blur' }],"dataList":_vm.form.brandTypes},on:{"change":_vm.onBrandTypeChange},model:{value:(_vm.form.selectedBrandType.id),callback:function ($$v) {_vm.$set(_vm.form.selectedBrandType, "id", $$v)},expression:"form.selectedBrandType.id"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"营销中心","placeholder":"自动带出","value":_vm.form.marketCenter.name,"disabled":"","prop":"marketCenter.code"}}),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"品牌区域编码","placeholder":"自动带出","value":_vm.form.marketCenter.brandRegionCode,"disabled":"","prop":"marketCenter.brandRegionCode"}}),(_vm.form.selectedBrandType.id === '1390119720541401089')?_c('RequiredFormItemSelect',{staticClass:"formItem",attrs:{"label":"是否新商","labelKey":"label","valueKey":"value","disabled":_vm.isOA,"prop":"isNewBusiness","dataList":[
      {
        label: '是',
        value: 1
      },
      {
        label: '否',
        value: 0
      }
    ]},model:{value:(_vm.form.isNewBusiness),callback:function ($$v) {_vm.$set(_vm.form, "isNewBusiness", $$v)},expression:"form.isNewBusiness"}}):_vm._e(),(_vm.isOA && _vm.form.lastYearRevenue !== '')?_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"delay-update":"","label":"上一年提货业绩（万）","prop":"lastYearRevenue","inputProps":{ type: 'number' },"disabled":""},model:{value:(_vm.form.lastYearRevenue),callback:function ($$v) {_vm.$set(_vm.form, "lastYearRevenue", $$v)},expression:"form.lastYearRevenue"}}):_vm._e(),_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"已代理系列","value":_vm.form.agentSeriesList.map(item => item.name).join(','),"placeholder":"请先选择授权城市","disabled":""}}),(_vm.isOA && _vm.form.selectedCancelSeriesList.length > 0)?_c('RequiredFormItemInput',{staticClass:"formItem",attrs:{"label":"取消授权系列","value":_vm.form.selectedCancelSeriesList.join(','),"disabled":""}}):_vm._e(),(_vm.isOA && _vm.form.canceledSeriesReason !== '')?_c('RequiredFormItemInput',{staticClass:"formItem",staticStyle:{"width":"100%"},attrs:{"label":"取消授权系列原因","prop":"canceledSeriesReason","disabled":""},model:{value:(_vm.form.canceledSeriesReason),callback:function ($$v) {_vm.$set(_vm.form, "canceledSeriesReason", $$v)},expression:"form.canceledSeriesReason"}}):_vm._e(),(_vm.isOA && (_vm.form.uploadFiles.length > 0 || _vm.form.remark !== ''))?_c('RemarkItem',{attrs:{"disabled":"","fileList":_vm.form.uploadFiles},on:{"onUpload":file => {
        _vm.form.uploadFiles = file.map(item => item.response)
      }},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }